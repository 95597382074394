<template>
  <div>

    <pageHeader :pagename="'账户管理'"/>

    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="搜索">
                <el-input placeholder="用户名，手机号，邮箱" v-model="data.keyword"></el-input>
            </el-form-item>
            
            <el-form-item label="账户状态">
                <el-select v-model="data.status" placeholder="账户状态">
                    <el-option label="所有状态" :value="0"></el-option>
                    <el-option label="正常" :value="1"></el-option>
                    <el-option label="禁用" :value="2"></el-option>
                    <el-option label="黑名单" :value="3"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item style="float:right">
                <el-button type="primary" @click="getList">确认查询</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row>
        <el-col :span="24">
            <el-table :data="pageCurrent.tableData" style="width: 100%">
                
                <el-table-column prop="id" label="系统ID" width="100" align="center"> </el-table-column>
                <el-table-column prop="name" label="账户名"> </el-table-column>
                <el-table-column prop="email" label="邮箱"> </el-table-column>
                <el-table-column prop="phone" label="手机号"> </el-table-column>

                <el-table-column prop="jurisdiction" label="用户组" width="200" fixed="right">
                    <template slot-scope="scope">
                        <template v-if="scope.row.account_group.group_name">
                            {{ scope.row.account_group.group_name }}
                        </template>
                        <template v-else>
                            未分配
                        </template>
                    </template>
                </el-table-column>

                <el-table-column prop="jurisdiction" label="账户权限" width="200" fixed="right">
                    <template slot-scope="scope">
                        <template v-if="scope.row.jurisdiction_roles.roles_name">
                            {{ scope.row.jurisdiction_roles.roles_name }}
                        </template>
                        <template v-else>
                            未分配
                        </template>
                    </template>
                </el-table-column>

                <el-table-column prop="jurisdiction" label="账户状态" width="200" fixed="right">
                    <template slot-scope="scope">
                    {{ doc.status_dict[scope.row.status] }}
                    </template>
                </el-table-column>

                <el-table-column label="" fixed="right" width="140">
                    <template slot-scope="scope">
                    <el-button @click="editAccount(scope.row.id)">查看账户详情</el-button>
                    </template>
                </el-table-column>

            </el-table>
        </el-col>
    </el-row>

    <Pagination :total="pageCurrent.total" :page.sync="pageCurrent.currentPage" @pagination="getList()" :limit.sync="pageCurrent.pageSize"/>
    
  </div>
</template>

<script>

import { personnelAccountQuery } from "@/api/manage/personnel_management.js"
import Pagination from "@/components/Pagination.vue";

export default {
  name: "start",
  data() {
    return {
      data: {
          keyword: "",
          status:""
      },
      variate: {},
      status: {},
      doc:{
          status_dict:{
            1: "正常",
            2: "禁用",
            3: "黑名单"
          }
      },
      pageCurrent: {
        tableData: [],
        currentPage: 1,
        total: 0,
        pageCount: 10
      },
    };
  },
  components: {
      Pagination
  },
  created() {
      this.getList()
  },
  mounted(){
    console.log(this.pageCurrent,"this.page")
  },
  methods: {
    getList() {
      this.$http(
        personnelAccountQuery({
          querypage: this.pageCurrent.currentPage,
          pagesize: this.pageCurrent.pageCount,
          keyword: this.data.keyword,
          status: this.data.status
        }),
        (res) => {
            console.log(res,'账户返回数据');
            this.pageCurrent.total = res.data.total;
            this.pageCurrent.currentPage = res.data.currentPage;
            this.pageCurrent.pageCount = res.data.pageCount;
            this.pageCurrent.tableData = res.data.result;
        }
      );
    },
    editAccount(ids) {
        this.$router.push({
            name:'personnelManagement_AccountManage_Info',
            query: {
                id:ids
            }
        })
    }
  },
};
</script>

<style type="scss">
</style>